<template>
	<div id="app" class="flex flex-column align-center">
		<nav class="px-6 py-6">
			<a href="/" class="logo-link">
				<img
					src="@/assets/logo.png"
					class="logo"
				>
			</a>
		</nav>
		<section
			class=
				"hero-wrapper flex flex-row w-full height-100vh justify-center">
			<div class="hero mt-8 px-6">
				<div ref="block" class="block"></div>
				<div class="hero-content">
					<h1 class="title relative flex-grow">Hi! I'm Jelle a UX Designer</h1>
					<p class="mb-6">
						Passionate about creating human-centered digital experiences.
					</p>
					<div class="buttons mb-10">
						<VButton class="btn-primary mb-4" @click="showPdf">
							See my portfolio
						</VButton>
						<VButton class="btn-secondary" @click="toAboutMe">
							More about me
						</VButton>
					</div>
				</div>
				<img
					class="image"
					src="@/assets/img/jelle_fogini_blazer.jpg"
					sizes="(max-width: 479px) 95vw,
					(max-width: 767px) 92vw, (max-width: 991px) 48vw,
					(max-width: 1919px) 40vw, 500px"
				>
			</div>

		</section>
		<section ref="aboutme" class="about flex w-full">
			<div class="content px-6 py-20">
				<p class="mb-4 mt-0">
					I’m more than a pixel pusher, I’m fascinated by the way people think,
					feel and behave. My goal is to make products that help people in daily
					life. I accomplish this by asking a lot of questions to understand the
					product and involve users with research and tests. I am very
					entrepreneurial, I like to think along within projects, improving
					processes and delivering according to best practices.
				</p>
				<p>
					You can find me browsing the internet every week for inspiration,
					going to meetups and learning how different companies use UX design,
					which is keeping me up-to-date with the latest trends. Besides design,
					I love music and playing board games.
				</p>
				<ul class="social-media">
					<li>
						<a href="mailto:jelleverschragen@gmail.com" target="_blank">
							<svg-filler path="img/gmail.svg" fill="#ffffff" />
						</a>
					</li>
					<li>
						<a href="https://dribbble.com/jelleverschragen" target="_blank">
							<svg-filler path="img/dribbble.svg" fill="#ffffff" />
						</a>
					</li>
					<li>
						<a
							href="https://www.linkedin.com/in/jelleverschragen/"
							target="_blank">
							<svg-filler path="img/linkedin.svg" fill="#ffffff" />
						</a>
					</li>
				</ul>
			</div>
		</section>
	</div>
</template>

<script>
import VButton from '@/components/Button';
import SvgFiller from 'vue-svg-filler';

export default {
	components: {
		VButton,
		SvgFiller,
	},
	data() {
		return {
			colorChangeTimeInterval: 3000,
			colors: ['#FBC5C6', '#9EFFD3', '#DDF2FF'],
			current: '#FBC5C6',
		};
	},
	mounted() {
		const { block } = this.$refs;
		setInterval(() => {
			block.style.backgroundColor = this.newColor();
		}, this.colorChangeTimeInterval);
	},
	methods: {
		showPdf() {
			window.open('./pdf/portfolio_jelleverschragen.pdf');
		},
		toAboutMe() {
			this.$refs.aboutme.scrollIntoView({
				behavior: 'smooth',
			});
		},
		newColor() {
			const number = Math.floor(Math.random() * this.colors.length);
			if (this.current === this.colors[number]) {
				return this.newColor();
			}
			this.current = this.colors[number];
			return this.current;
		},
	},
};
</script>

<style lang="scss">
nav {
	position: absolute;
	top: 0;
	left: 0;
}
.logo-link{
	display: flex;
}
.logo {
	width: 48px;
	height: 48px;
}
.title {
  z-index: 20;
  color: $dark;
}
img {
	max-width: 100%;
	height: auto;
}
.w-full{
	width: 100%;
}
.relative{
	position: relative;
}

.block {
	position: absolute;
	left: auto;
	right: 0;
	top: 0;
	bottom: auto;
	z-index: -1;
	width: 100%;
	height: 100vh;
	background-color: $light-green;
	transition: 3s background-color;
}
.hero {
	display: flex;
	width: auto;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	margin-top: 6rem;
}
.hero-content{
	width: 100%;
}
.image {
	margin-bottom: -40px;
	z-index: 20;
}
.about{
	background-color: $dark;
	color: $white;
}
.header {
  display: flex;
}
.intro{
	font-size: 18px;
	line-height: 32px;
}
.text--center {
  text-align: center;
}

.content{
	max-width: 1024px;
	margin-left: auto;
	margin-right: auto;
}

.social-media{
	list-style: none;
	padding: 0;
	margin-top: 32px;
	margin-bottom: 0;
}
.social-media li{
	display: inline-block;
	margin-right: 12px;
}
.social-media li a{
	width: 48px;
	height: 48px;
	background-color: $green;
	display: flex;
	justify-content: center;
	align-items: center;
}
.social-media li a:hover{
	opacity: 0.8;
}

@media (min-width: 960px) {
	.height-100vh{
		height: 100vh;
	}
	.hero{
		width: 1024px;
		flex-direction: row;
	}
	.hero-content{
		padding: 2rem;
		width: 50%;
	}
	.block{
		width: 40%;
		height: 70vh;
	}
	.image{
		margin-bottom: 0;
		width: 40%;
	}
	.name {
		text-align: left;
		margin: 0;
	}
}
</style>
