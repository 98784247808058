<template>
	<router-view/>
</template>

<script>
export default {
	name: 'App',
	metaInfo() {
		return {
			title: 'Jelle Verschragen - UX Designer',
			meta: [
				{ name: 'description', content: 'Jelle is passionate about creating human-centered digital experiences.' },
				{ property: 'og:title', content: 'Jelle Verschragen - UX Designer' },
				{ property: 'og:description', content: 'Jelle is passionate about creating human-centered digital experiences.' },
			],
		};
	},
};
</script>

<style lang="scss">
@import "@/assets/styles/app.scss";
</style>

<style lang="scss">
body {
	box-sizing: border-box;
	padding: 0;
	margin: 0;
	width: 100vw;
	height: 100vh;
	overflow-x: hidden;
}

#app {
	font-family: $font-family;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
</style>
