<template functional>
	<button
		:ref="data.ref"
		class="px-12 py-4 button"
		:class="[
			data.class,
			data.staticClass,
		]"
		:style="[
			data.style,
			data.staticStyle,
		]"
		v-bind="data.attrs"
		v-on="listeners"
	>
		<slot />
	</button>
</template>

<script>
export default {};
</script>

<style lang="scss">
.button {
  cursor: pointer;
  line-height: 1.5;
  letter-spacing: 0.12px;
  width: 100%;
  font-size: 16px;
  border: 1px solid;
  text-transform: uppercase;
}
.btn-primary{
	background-color: $green;
	color: $white;
	border-color: $green;
}
.btn-primary:hover{
	background-color: $green-lighter;
	border-color: $green-lighter;
}
.btn-secondary{
	border-color: $green;
	color: $green;
	background-color: $white;
}
.btn-secondary:hover{
	color: $white;
	background-color: $green;
}

@media (min-width: 640px) {
.button {
	width: auto;
}
.btn-primary{
	margin-right: 1rem;
}
}
</style>
